import { AxiosRequestConfig } from 'axios'
import BaseAPI from 'src/api/BaseAPI'
import { APIAPIKey } from 'src/model/api/automation/APIAPIKey'
import { APIGetAutomateResponse } from 'src/model/api/automation/APIGetAutomateResponse'
import { APIPutAutomateResponse } from 'src/model/api/automation/APIPutAutomateResponse'
import { APIAutomationItem } from 'src/model/api/automation/automate/APIAutomationItem'
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { UUID } from 'src/util/types'

/**
 * Client for requests sent to the Public API.
 */
export default class AutomationAPI extends BaseAPI {
  /**
   * Fetches a list of Public API keys associated with the specified company. NOTE: This does not return the actual API
   * key string itself. A separate GET request to the endpoint with the specific key UUID is required to get that.
   *
   * @param companyUUID
   */
  async getAPIKeys(companyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/keys/`
    return await this.withAuth().getAllPages<APIAPIKey>(url)
  }

  /**
   * Creates a new API Key with a given name for a specified company.
   *
   * @param companyUUID
   * @param keyName A custom name for the key for identification purposes
   *
   * @return an APIAPIKey object. NOTE: The actual `key` field of the object itself will be blank. A separate GET
   * request to the endpoint with the specific key UUID is required to get that.
   */
  async createAPIKey(companyUUID: UUID, keyName: string) {
    const url = `/team/api/company/${companyUUID}/keys/`
    const postData: Partial<APIAPIKey> = {
      name: keyName,
    }
    return await this.withAuth().post<APIAPIKey>(url, postData)
  }

  /**
   * Removes an API key with a given name from a specified company
   * @param companyUUID
   * @param keyUUID
   */
  async removeAPIKey(companyUUID: UUID, keyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/keys/${keyUUID}/`
    return await this.withAuth().delete(url)
  }

  /**
   * Gets a particular API key for a given company and key UUID. This is the only GET API call that will yield the actual
   * key string value.
   *
   * @param companyUUID
   * @param keyUUID
   */
  async getAPIKey(companyUUID: UUID, keyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/keys/${keyUUID}/?decode=true`
    return await this.withAuth().get<APIAPIKey>(url)
  }

  /**
   * Updates an existing API Key with a new name for a specified company
   *
   * @param companyUUID
   * @param keyUUID
   * @param newName
   */
  async updateAPIKey(companyUUID: UUID, keyUUID: UUID, newName: string) {
    const url = `/team/api/company/${companyUUID}/keys/${keyUUID}/`
    const postData = {
      name: newName,
    }
    return await this.withAuth().patch(url, postData)
  }

  /**
   * Regenerates an existing API key for a given company to have a new key string.
   *
   * @param companyUUID
   * @param keyUUID
   */
  async regenerateAPIKey(companyUUID: UUID, keyUUID: UUID) {
    const url = `/team/api/company/${companyUUID}/keys/${keyUUID}/regenerate/`
    return await this.withAuth().post<APIAPIKey>(url)
  }

  /**
   * Fetches automation templates for a company
   *
   * @param companyUUID
   */
  async getAutomationTemplates(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ) {
    const url = `/team/api/company/${companyUUID}/contents/?is_automation_template=true&is_archived=false`
    return await this.withAuth().getPage<APIPresentation[]>(
      url,
      page,
      pageSize,
      config,
    )
  }

  /**
   * Fetches automation data for a given template (lookup by share alias)
   *
   * @param apiKey The API key (not the UUID of the key, but the actual key itself--it will need to be unwrapped)
   * @param shareAlias
   */
  async getAutomationDataByShareAlias(apiKey: string, shareAlias: string) {
    const url = `/pubapi/content/automate/${shareAlias}/`
    return await this.withPublicAPIKey(apiKey).get<APIGetAutomateResponse>(url)
  }

  /**
   * Performs a PUT operation to execute automation on a given template via its share alias. If the payload contains any
   * file uploads, the API will not upload the files--that will need to be done separately. This PUT requests will
   * generate the signed upload URLs and return them in the response, whereupon one can upload the files to each URL.
   *
   * @param apiKey
   * @param shareAlias
   * @param payload An array of objects intended to provide data for the resulting presentation.
   */
  async performAutomation(
    apiKey: string,
    shareAlias: string,
    payload: APIAutomationItem[],
  ) {
    const url = `/pubapi/content/automate/${shareAlias}/`
    return await this.withPublicAPIKey(apiKey).put<APIPutAutomateResponse>(
      url,
      payload,
    )
  }

  /**
   * Creates a new company for a given organization associated with the API key.
   *
   * @param apiKey A managing company API key
   * @param organizationUUID The UUID of the organization to which the new company will belong
   * @param packageUUID The UUID of the package that will be cloned to the new company
   * @param companyName The name of the new company
   * @param firstName The first name of the admin user that will be created for the new company
   * @param lastName The last name of the admin user that will be created for the new company
   * @param email The email of the admin user that will be created for the new company
   * @returns New company Record
   */
  async createCompany(
    apiKey: string,
    organizationUUID: UUID,
    packageUUID: UUID,
    companyName: string,
    firstName: string,
    lastName: string,
    email: string,
    extraActions: { [key: string]: unknown } = {},
  ) {
    const url = '/pubapi/company/'
    const postData = {
      organization_uuid: organizationUUID,
      package_uuid: packageUUID,
      company_name: companyName,
      first_name: firstName,
      last_name: lastName,
      email,
      extra_actions: extraActions,
    }
    return await this.withPublicAPIKey(apiKey).post(url, postData)
  }

  /**
   * Creates a new user for a given company associated with the API key.
   *
   * @param apiKey
   * @param email
   * @param firstName
   * @param lastName
   * @param role_uuid
   * @param team_uuids
   */
  async createUser(
    apiKey: string,
    email: string,
    firstName: string,
    lastName: string,
    role_uuid: string | null = null,
    team_uuids: string[] = [],
  ) {
    const url = '/pubapi/company/user/'
    const postData = {
      email,
      first_name: firstName,
      last_name: lastName,
      role: role_uuid,
      teams: team_uuids,
    }
    return await this.withPublicAPIKey(apiKey).post(url, postData)
  }

  /**
   * Fetches a list of teams for a given company associated with the API key.
   * @param apiKey
   */
  async getTeams(apiKey: string) {
    const url = '/pubapi/company/teams/'
    return await this.withPublicAPIKey(apiKey).get(url)
  }

  /**
   * Fetches a list of roles for a given company associated with the API key.
   * @param apiKey
   */
  async getRoles(apiKey: string) {
    const url = '/pubapi/company/roles/'
    return await this.withPublicAPIKey(apiKey).get(url)
  }
}
