import { CSpinner } from '@coreui/react-pro'
import { CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { observer } from 'mobx-react'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { EnvConfig } from 'src/util/EnvConfig'
import { Feature } from 'src/util/features/Feature'
import { StyleUtil } from 'src/util/StyleUtil'
import { AuthRoute } from './AuthRoute'
import PresentLayout from './layout/PresentLayout'

// routes config
import {
  companyRoutes,
  defaultRoutes,
  internalRoutes,
  marketplaceAdminRoutes,
  marketplaceRoutes,
  noAuthRoutes,
  presentRoutes,
} from './routes'
import { useUIStore, useUserStore } from './store'
import { ExternalAnalyticsUtil } from './util/analytics/ExternalAnalyticsUtil'
import { PresentationsAnalyticsUtil } from './util/analytics/PresentationsAnalyticsUtil'

require(`./scss/${process.env.REACT_APP_BRAND}/style.scss`)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const InternalUserLayout = React.lazy(
  () => import('./layout/InternalUserLayout'),
)
const ContainerLayout = React.lazy(() => import('./layout/ContainerLayout'))
const MarketplaceLayout = React.lazy(() => import('./layout/MarketplaceLayout'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const FeatureGate = React.lazy(() => import('./components/FeatureGate'))

const App = observer(() => {
  const uiStore = useUIStore()
  const userStore = useUserStore()

  const [browserZoomLevel, setBrowserZoomLevel] = useState(
    (window.outerWidth - 8) / window.innerWidth,
  )

  const onWindowResize = useCallback(() => {
    uiStore.setViewportDimensions(window.innerWidth, window.innerHeight)
    setBrowserZoomLevel((window.outerWidth - 8) / window.innerWidth)
  }, [uiStore])

  const didBootstrapComplete = useRef(false)

  useEffect(() => {
    if (!didBootstrapComplete.current) {
      didBootstrapComplete.current = true

      Sentry.init({
        dsn: EnvConfig.getSentryDSN(),
        environment: EnvConfig.getBackendEnvironment().toLowerCase(),
        release: `ingage-teams@${EnvConfig.getVersion()}`,
        // @ts-ignore
        integrations: [
          new BrowserTracing(),
          new CaptureConsole({
            levels: ['error'],
          }),
        ],
        tracesSampleRate: 0.0,
      })
      if (userStore.profile?.id) {
        Sentry.setUser({ id: userStore.profile?.id })
      }
    }

    window.addEventListener('resize', () => onWindowResize())
    return () => window.removeEventListener('resize', () => onWindowResize())
  }, [onWindowResize, userStore.profile?.id])

  useEffect(() => {
    PresentationsAnalyticsUtil.registerSuperProperties({
      Device: 'teams-web',
    })
  }, [])

  useEffect(() => {
    if (userStore.profile?.id) {
      ExternalAnalyticsUtil.addPerson(userStore.profile.id, {
        $email: userStore.profile.email,
        $first_name: userStore.profile.firstName,
        $last_name: userStore.profile.lastName,
      })
      PresentationsAnalyticsUtil.identifyUser(userStore.profile.id)
      PresentationsAnalyticsUtil.registerSuperProperties({
        Paid: userStore.profile.isPaid,
        is_internal: userStore.profile.isInternal,
      })
    }
  }, [userStore.profile])

  useEffect(() => {
    if (window.innerWidth > StyleUtil.viewportOverrideWidth) {
      uiStore.setIsSidebarOpen(true)
    }
  }, [browserZoomLevel, uiStore])

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
            <CSpinner color="primary" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<AuthRoute />}>
            <Route element={<DefaultLayout />}>
              <Route element={<ContainerLayout />}>
                <Route index element={<Navigate to="/dashboard" />} />
                {defaultRoutes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  )
                })}
                {companyRoutes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  )
                })}
              </Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route element={<MarketplaceLayout />}>
                {marketplaceRoutes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  )
                })}
              </Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route element={<InternalUserLayout />}>
                {internalRoutes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  )
                })}
              </Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route
                element={<FeatureGate features={[Feature.MARKETPLACE_ADMIN]} />}
              >
                {marketplaceAdminRoutes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<route.component />}
                      />
                    )
                  )
                })}
              </Route>
            </Route>
            <Route element={<PresentLayout />}>
              {presentRoutes.map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      element={<route.component />}
                    />
                  )
                )
              })}
            </Route>
          </Route>
          {noAuthRoutes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.component />}
                />
              )
            )
          })}
          <Route path="*" element={<Dashboard />} />{' '}
          {/* if path does not exist */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
})
export default App
