import React from 'react'
import { CSpinner } from '@coreui/react-pro'

const FallbackSpinner = () => (
  <div className="loading-container w-100 d-flex flex-row justify-content-center align-items-center">
    <CSpinner color="primary" />
  </div>
)

export default FallbackSpinner
