/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Navigate,
  useLocation,
  useSearchParams,
  useNavigate,
  Outlet,
} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import AutoLoginFromClient from 'src/components/AutoLoginFromClient'
import MessageModal from 'src/components/MessageModal'
import { ExternalAnalyticsEvent } from 'src/model/external-analytics/ExternalAnalyticsEvent'
import { ExternalAnalyticsUtil } from 'src/util/analytics/ExternalAnalyticsUtil'
import { EnvConfig } from 'src/util/EnvConfig'
import { StringUtil } from 'src/util/StringUtil'
import { UserUtil } from 'src/util/UserUtil'
import { useAnalyticsStore, useTeamsStore, useUserStore } from './store'

export const AuthRoute = () => {
  const [params] = useSearchParams()
  const analyticsStore = useAnalyticsStore()
  const teamsStore = useTeamsStore()
  const userStore = useUserStore()
  const location = useLocation()
  const navigate = useNavigate()

  const [isReloading, setIsReloading] = useState(true)
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  useEffect(() => {
    // If user is somehow authenticated but doesn't have permissions to any items within, send them to the download page.
    // Note: we don't want to do this if the user is doing an autologin, because we prematurely flip the
    // `isAuthenticated` value to `true` early in order to make some preliminary validation requests before we get the list of
    // companies. This should only trigger on non-internal users whose access to companies has changed since they
    // logged in (i.e. they were removed (read: laid off) by an admin).
    const getTeamsAndCheckPermissions = async () => {
      const teams = await teamsStore.getAllTeams()
      if (
        userStore.isAuthenticated &&
        !userStore.profile?.isInternal &&
        !UserUtil.doesUserHaveRequiredAccessLevelToAnyCompany(
          teamsStore.companies,
        ) &&
        !UserUtil.doesUserHaveRequiredAccessLevelToAnyTeam(teams) &&
        !isAutoLoginRequest()
      ) {
        console.warn(
          'User does not have access rights to Ingage Teams site. Logging them out...',
        )
        ExternalAnalyticsUtil.trackEvent(
          ExternalAnalyticsEvent.PREVIOUSLY_AUTHORIZED_USER_NO_LONGER_AUTHORIZED,
          userStore.profile,
        )
        userStore.logout()
      }

      // supports getting analytics options from query string parameters
      // sets an initial set of params in local storage
      const locationArray = location.pathname.split('/')
      if (
        locationArray[locationArray.length - 1] === 'analytics' &&
        location.search
      ) {
        const urlParamsStr = new URLSearchParams(location.search)
        const urlParamsObj = Object.fromEntries(urlParamsStr)
        analyticsStore.setInitialAnalyticsParams(urlParamsObj)
      }
    }
    getTeamsAndCheckPermissions()
  }, [])

  useEffect(() => {
    if (location && isReloading && !isAutoLoginRequest()) {
      if (userStore.profile && !userStore.profile?.eulaAgreedAt) {
        forceLogout()
      }
      navigate(location)
      setIsReloading(false)
    }
  }, [location])

  useEffect(() => {
    // if SSO login
    // prevent error that occurs if validating Auth0 while refreshing API Auth
    if (
      EnvConfig.shouldAllowSSO() &&
      Date.now() < userStore.authCheckTimestamp
    ) {
      if (userStore.isAuthMethodSSO) {
        if (Date.now() > userStore.authZeroCheckTimestamp) {
          handleAuth0RefreshToken()
        }
      }
    }
  }, [location])

  const handleAuth0RefreshToken = async () => {
    let auth0AccessToken
    try {
      auth0AccessToken = await getAccessTokenSilently()
      userStore.setAuthZeroCheckTimestamp(
        StringUtil.parseJWT(auth0AccessToken).exp * 1000,
      )
      console.log('Auth0 Access Token Refreshed')
    } catch (err) {
      forceLogout()
      console.log(err)
    }

    // this block gets the claims which include user info
    // this is not used at the current time in the refresh flow
    if (auth0AccessToken) {
      const claims = await getIdTokenClaims()
      if (claims) {
        StringUtil.parseJWT(claims.__raw)
      }
    }
  }

  const forceLogout = async () => {
    await userStore.logout()
    window.location.href = '/auth/logout'
  }

  const isAutoLoginRequest = () => {
    return (
      params.get('accessToken') &&
      params.get('userID') &&
      params.get('companyID') &&
      params.get('platform')
    )
  }

  const getUnAuthedDestination = () => {
    const accessToken = params.get('accessToken')
    const userUUID = params.get('userID')
    const companyUUID = params.get('companyID')
    const platform = params.get('platform')

    if (accessToken && userUUID && companyUUID && platform) {
      return (
        <AutoLoginFromClient
          accessToken={accessToken}
          userUUID={userUUID}
          companyUUID={companyUUID}
          platform={platform}
        />
      )
    } else {
      return (
        <Navigate
          to={'/auth'}
          state={{ from: location }} // <-- pass in route state
          replace
        />
      )
    }
  }

  if (isAutoLoginRequest() && !userStore.isHydrated) {
    return <></>
  }

  return userStore.isAuthenticated &&
    (!isAutoLoginRequest() || userStore.isConsoleAuthenticated) ? (
    <React.Fragment>
      <MessageModal />
      <Outlet />
    </React.Fragment>
  ) : (
    getUnAuthedDestination()
  )
}
