import { Environment } from 'src/store/AppConfigStore'
import { LocalStorage } from 'src/store/LocalStorage'

export namespace EnvConfig {
  // region Getters for .env vars based on type and the currently selected server environment
  export const getStringEnvVar = (envVar: string, defaultValue = '') => {
    return process.env[envVar] ?? defaultValue
  }

  export const getArrayEnvVar = (envVar: string, defaultValue = []) => {
    return process.env[envVar]?.split(',') ?? defaultValue
  }

  /**
   * dotenv should parse `true` and `false` to booleans, but mixed cases or other values may
   * not be ingested properly, so we cast all values to a string and compare to `true` to get
   * a safe boolean value that defaults to `false` if an invalid value is ingested
   *
   * @param envVar
   * @param defaultValue
   */
  export const getBooleanEnvVar = (envVar: string, defaultValue = false) => {
    if (process.env[envVar] === undefined) {
      return defaultValue
    }
    return `${process.env[envVar]}`.toLowerCase() === 'true'
  }

  export const getNumberEnvVar = (envVar: string, defaultValue = 0) => {
    const val = process.env[envVar]
    if (val === undefined) {
      return defaultValue
    }
    try {
      return parseInt(val)
    } catch (e) {
      return defaultValue
    }
  }

  export const getStringEnvVarValueForServerEnvironment = (
    envVar: string,
    environment: Environment,
    defaultValue = '',
  ) => {
    return getStringEnvVar(
      `REACT_APP_${environment.toUpperCase()}_${envVar}`,
      defaultValue,
    )
  }

  export const getBooleanEnvVarValueForServerEnvironment = (
    envVar: string,
    environment: Environment,
    defaultValue = false,
  ) => {
    return getBooleanEnvVar(
      `REACT_APP_${environment.toUpperCase()}_${envVar}`,
      defaultValue,
    )
  }

  export const shouldAllowEnvSwitcher = () => {
    return (
      getStringEnvVar('REACT_APP_DEFAULT_ENV', 'Development') !==
        'Production' && getBooleanEnvVar('REACT_APP_ALLOW_ENV_SWITCHER')
    )
  }

  export const getMaxAllowedInternalCompanyMemberships = () => {
    return getNumberEnvVar(
      'REACT_APP_MAX_ALLOWED_INTERNAL_COMPANY_MEMBERSHIPS',
      30,
    )
  }

  export const shouldAllowSSO = () => {
    return getBooleanEnvVar('REACT_APP_ALLOW_SSO')
  }

  export const forceResendInviteLimit = () => {
    return parseInt(getStringEnvVar('REACT_APP_FORCE_RESEND_INVITE_LIMIT'))
  }

  export const getCacheTTL = () => {
    return getNumberEnvVar('REACT_APP_CACHE_TTL', 300000)
  }

  export const getStalenessTTL = () => {
    return getNumberEnvVar('REACT_APP_STALENESS_TTL', 300000)
  }

  export const getDefaultEnvironment = () => {
    switch (getStringEnvVar('REACT_APP_DEFAULT_ENV', 'Development')) {
      default:
      case 'Development':
        return Environment.DEVELOPMENT
      case 'QA':
        return Environment.QA
      case 'Production':
        return Environment.PRODUCTION
      case 'Local':
        return Environment.LOCAL
    }
  }
  // endregion Getters for .env vars

  export const getBaseURL = () => {
    return getStringEnvVarValueForServerEnvironment(
      'BASE_URL',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getClientID = () => {
    return getStringEnvVarValueForServerEnvironment(
      'CLIENT_ID',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getAPIVersion = () => {
    return getStringEnvVarValueForServerEnvironment(
      'API_VERSION',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getBuild = () => {
    return getStringEnvVarValueForServerEnvironment(
      'BUILD',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getVersion = () => {
    return getStringEnvVarValueForServerEnvironment(
      'VERSION',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getPresentationsMixpanelToken = () => {
    return getStringEnvVarValueForServerEnvironment(
      'PRESENTATIONS_MIXPANEL_TOKEN',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getExternalMixpanelToken = () => {
    return getStringEnvVarValueForServerEnvironment(
      'EXTERNAL_MIXPANEL_TOKEN',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getAuth0Domain = () => {
    return getStringEnvVarValueForServerEnvironment(
      'AUTH0_DOMAIN',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getAuth0BrandedDomain = () => {
    return getStringEnvVarValueForServerEnvironment(
      'AUTH0_BRANDED_DOMAIN',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getAuth0ClientID = () => {
    return getStringEnvVarValueForServerEnvironment(
      'AUTH0_CLIENT_ID',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getBackendEnvironment = () => {
    const environment = getStringEnvVarValueForServerEnvironment(
      'BACKEND_ENV',
      LocalStorage.getInstance().getServerEnvironment(),
    )
    switch (environment?.toLowerCase()) {
      case 'local':
        return Environment.LOCAL
      default:
      case 'development':
        return Environment.DEVELOPMENT
      case 'qa':
        return Environment.QA
      case 'production':
        return Environment.PRODUCTION
    }
  }

  export const shouldShowAnalyticsCharts = () => {
    return getBooleanEnvVarValueForServerEnvironment(
      'SHOW_ANALYTICS_CHARTS',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getWebURL = () => {
    return getStringEnvVarValueForServerEnvironment(
      'WEB_URL',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getSentryDSN = () => {
    return getStringEnvVarValueForServerEnvironment(
      'SENTRY_DSN',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }

  export const getFourSeasonsApprovalEmail = () => {
    return getStringEnvVarValueForServerEnvironment(
      'FOUR_SEASONS_APPROVAL_EMAIL',
      LocalStorage.getInstance().getServerEnvironment(),
    )
  }
}
