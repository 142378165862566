/**
 * Contains store methods for interacting with Automation or the Public API.
 */
import { AxiosRequestConfig } from 'axios'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { API } from 'src/api/API'
import { APIAutomationItem } from 'src/model/api/automation/automate/APIAutomationItem'
import { APIPresentation } from 'src/model/api/presentation/APIPresentation'
import { AutomationModel } from 'src/model/automation/AutomationModel'
import { GenericAutomationResponse } from 'src/model/automation/GenericAutomationResponse'
import { PresentationsModel } from 'src/model/presentation/PresentationsModel'
import { Presentation } from 'src/model/presentation/Presentation'
import { RootStore } from 'src/store/RootStore'
import { UUID } from 'src/util/types'
import { BaseStore } from './BaseStore'

export class AutomationStore implements BaseStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
    makePersistable(this, {
      name: 'TeamsAutomation',
      properties: [],
      storage: window.localStorage,
    }).then((r) => {
      console.debug('Persisting data for AutomationStore')
    })
  }

  /**
   * Fetches a list of Public API keys associated with the specified company.
   *
   * @param companyUUID
   */
  async getAPIKeys(companyUUID: UUID) {
    try {
      const res = await API.getInstance().automationAPI.getAPIKeys(companyUUID)
      if (res) {
        return res.map((apiApiKey) =>
          AutomationModel.apiKeyFromAPIModel(apiApiKey),
        )
      }
    } catch (e) {
      console.error(`Unable to fetch API keys for company: ${companyUUID}`, e)
    }
    return []
  }

  /**
   * Creates a new API Key with a given name for a specified company.
   *
   * @param companyUUID
   * @param keyName A custom name for the key for identification purposes
   *
   */
  async createAPIKey(companyUUID: UUID, keyName: string) {
    try {
      const res = await API.getInstance().automationAPI.createAPIKey(
        companyUUID,
        keyName,
      )
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error(`Unable to create API key for company ${companyUUID}`, e)
    }
  }

  /**
   * Removes an API key with a given name from a specified company
   * @param companyUUID
   * @param keyUUID
   */
  async removeAPIKey(companyUUID: UUID, keyUUID: UUID) {
    const response: GenericAutomationResponse = {
      wasSuccessful: false,
      message: '',
    }
    try {
      const res = await API.getInstance().automationAPI.removeAPIKey(
        companyUUID,
        keyUUID,
      )
      if (res.status === 204) {
        response.wasSuccessful = true
      } else if (res.status === 400) {
        response.message = Object.values(res.data).join(', ')
      }
    } catch (e) {
      console.error(
        `Unable to delete API key ${keyUUID} for company ${companyUUID}`,
        e,
      )
    }
    return response
  }

  /**
   * Gets a particular API key for a given company and key UUID. This is the only GET API call that will yield the actual
   * key string value.
   *
   * @param companyUUID
   * @param keyUUID
   */
  async getAPIKey(companyUUID: UUID, keyUUID: UUID) {
    try {
      const res = await API.getInstance().automationAPI.getAPIKey(
        companyUUID,
        keyUUID,
      )
      if (res.data) {
        return AutomationModel.apiKeyFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to fetch API key ${keyUUID} for company ${companyUUID}`,
        e,
      )
    }
  }

  /**
   * Updates an existing API Key with a new name for a specified company
   *
   * @param companyUUID
   * @param keyUUID
   * @param newName
   */
  async updateAPIKey(companyUUID: UUID, keyUUID: UUID, newName: string) {
    const response: GenericAutomationResponse = {
      wasSuccessful: false,
      message: '',
    }
    try {
      const res = await API.getInstance().automationAPI.updateAPIKey(
        companyUUID,
        keyUUID,
        newName,
      )
      if (res.status === 200) {
        response.wasSuccessful = true
      } else if (res.status === 400) {
        response.message = Object.values(res.data).join(', ')
      }
    } catch (e) {
      console.error(
        `Unable to update API key ${keyUUID} for company ${companyUUID}`,
        e,
      )
    }
    return response
  }

  /**
   * Regenerates an existing API key for a given company to have a new key string.
   *
   * @param companyUUID
   * @param keyUUID
   */
  async regenerateAPIKey(companyUUID: UUID, keyUUID: UUID) {
    try {
      const res = await API.getInstance().automationAPI.regenerateAPIKey(
        companyUUID,
        keyUUID,
      )
      if (res.data) {
        return AutomationModel.apiKeyFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to regenerate API key ${keyUUID} for company ${companyUUID}`,
        e,
      )
    }
  }

  /**
   * Fetches automation templates for a given company.
   *
   * @param companyUUID
   * @param page
   * @param pageSize
   * @param config
   */
  async getAutomationTemplates(
    companyUUID: UUID,
    page: number,
    pageSize: number,
    config?: AxiosRequestConfig,
  ): Promise<{ totalCount: number; templates: Presentation[] }> {
    try {
      const res = await API.getInstance().automationAPI.getAutomationTemplates(
        companyUUID,
        page,
        pageSize,
        config,
      )
      if (res.data) {
        return {
          totalCount: res.headers['total-count']
            ? parseInt(res.headers['total-count'])
            : 0,
          templates: res.data.map((apiPresentation: APIPresentation) =>
            PresentationsModel.presentationFromAPIModel(apiPresentation),
          ),
        }
      }
    } catch (e) {
      console.error('Unable to fetch automation templates', e)
    }

    return {
      totalCount: 0,
      templates: [],
    }
  }

  /**
   * Fetches automation data for a given template (lookup by share alias)
   *
   * @param apiKey The API key (not the UUID of the key, but the actual key itself--it will need to be unwrapped)
   * @param shareAlias
   */
  async getAutomationDataByShareAlias(apiKey: string, shareAlias: string) {
    try {
      const res =
        await API.getInstance().automationAPI.getAutomationDataByShareAlias(
          apiKey,
          shareAlias,
        )
      if (res.data) {
        return AutomationModel.getAutomateResponseFromAPIModel(res.data)
      }
    } catch (e) {
      console.error(
        `Unable to fetch automation data by share alias (${shareAlias})`,
        e,
      )
    }
    return null
  }

  /**
   * Performs a PUT operation to execute automation on a given template via its share alias. If the payload contains any
   * file uploads, the API will not upload the files--that will need to be done separately. This PUT requests will
   * generate the signed upload URLs and return them in the response, whereupon one can upload the files to each URL.
   *
   * @param apiKey
   * @param shareAlias
   * @param payload An array of objects intended to provide data for the resulting presentation.
   */
  async performAutomation(
    apiKey: string,
    shareAlias: string,
    payload: APIAutomationItem[],
  ) {
    try {
      const res = await API.getInstance().automationAPI.performAutomation(
        apiKey,
        shareAlias,
        payload,
      )
      if (res.data) {
        return res.data
      }
    } catch (e) {
      console.error(
        `Unable to perform automation for the given share alias: ${shareAlias}`,
        e,
      )
    }
    return null
  }
}
