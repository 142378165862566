const articlesPrepositionsConjunctions = [
  'and',
  'as',
  'as if',
  'as long as',
  'at',
  'but',
  'by',
  'even if',
  'for',
  'from',
  'if',
  'if only',
  'in',
  'into',
  'like',
  'near',
  'now that',
  'nor',
  'of',
  'off',
  'on',
  'on top of',
  'once',
  'onto',
  'or',
  'out of',
  'over',
  'past',
  'so',
  'so that',
  'than',
  'that',
  'till',
  'to',
  'up',
  'upon',
  'with',
  'when',
  'yet',
]

export namespace StringUtil {
  export const pluralize = (
    word: string,
    count: number,
    suffix: string = 's',
  ) => {
    return count === 1 ? word : `${word}${suffix}`
  }

  // format string to title case
  export const titleCase = (text: string) => {
    // split text by spaces and/or dashes
    const textArray = text.toLowerCase().split(/[ -]/)
    const final = []
    for (const word of textArray) {
      if (articlesPrepositionsConjunctions.includes(word)) {
        final.push(word.toLowerCase())
      } else {
        final.push(`${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      }
    }
    return final.join(' ')
  }

  // format seconds to a string representation
  export const formatDuration = (seconds: number) => {
    seconds = Number(seconds)
    const days = String(Math.floor(seconds / (3600 * 24))).padStart(2, '0')
    const hours = String(Math.floor((seconds % (3600 * 24)) / 3600)).padStart(
      2,
      '0',
    )
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0')
    const secs = String(
      Math.round(Math.floor((seconds % 60) * 100) / 100),
    ).padStart(2, '0')

    return `${days}:${hours}:${mins}:${secs}`
  }

  /**
   * Formats a number of bytes to human-readable amounts.
   *
   * @param bytes total bytes
   * @param decimals number of decimal places
   */
  export const formatBytes = (bytes?: number, decimals = 2) => {
    if (!bytes || bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  /**
   * Capitalizes the first letter of a string (and only the first letter).
   *
   * @param str
   */
  export const capitalize = (str: string) => {
    return `${str.replace(/^\w/, (c) => c.toUpperCase())}`
  }

  export const regexForEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  export const regexForAnalyticsTagValidation = /^[a-zA-Z0-9]*$/

  export const regexForUUIDv4 =
    /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}(?:\/.*)?$/i

  export const defaultUUID = '00000000-0000-0000-0000-000000000000'

  export const lowerKebabCase = (string: string) => {
    return `${string}`.replace(/ /g, '-').toLowerCase()
  }

  export const parseJWT = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const payload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`
        })
        .join(''),
    )
    return JSON.parse(payload)
  }

  export const labelFromHyphenatedOrSnakeCase = (str: string = '') =>
    capitalize(str.replace(/(_|-)/g, ' '))

  export const formatEventName = (event: string) => {
    const eventArray = event.split('-')
    if (eventArray.length > 1) {
      return titleCase(eventArray[1].replace('story', 'presentation'))
    }
    return titleCase(event.replace('story', 'presentation'))
  }
}
