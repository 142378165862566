import { CustomField } from 'src/model/customfields/CustomField'
import { APICustomField } from 'src/model/api/customfields/APICustomField'
import { CustomFieldValue } from 'src/model/customfields/CustomFieldValue'
import { APICustomFieldValue } from 'src/model/api/customfields/APICustomFieldValue'
import { CustomFieldUpdateProps } from 'src/model/customfields/CustomFieldUpdateProps'
import { CustomFieldCreateProps } from 'src/model/customfields/CustomFieldCreateProps'
import { APICustomFieldUpdateProps } from 'src/model/api/customfields/APICustomFieldUpdateProps'
import { APICustomFieldCreateProps } from 'src/model/api/customfields/APICustomFieldCreateProps'
import { CustomFieldValueCreateProps } from 'src/model/customfields/CustomFieldValueCreateProps'
import { APICustomFieldValueCreateProps } from 'src/model/api/customfields/APICustomFieldValueCreateProps'

export namespace CustomFieldsModel {
  export const customFieldFromAPIModel = (
    apiCustomField: APICustomField,
  ): CustomField => {
    const customField: CustomField = {
      uuid: apiCustomField.uuid,
      name: apiCustomField.name,
      description: apiCustomField.description,
      slug: apiCustomField.slug,
      dataType: apiCustomField.data_type,
      defaultValue: apiCustomField.default_value,
      associatedContentType: apiCustomField.associated_content_type,
    }
    return customField
  }

  export const apiCustomFieldCreatePropsFromModel = (
    customFieldCreateProps: CustomFieldCreateProps,
  ): APICustomFieldCreateProps => {
    const apiCustomFieldCreateProps: APICustomFieldCreateProps = {
      name: customFieldCreateProps.name,
      description: customFieldCreateProps.description,
      slug: customFieldCreateProps.slug,
      data_type: customFieldCreateProps.dataType,
      default_value: customFieldCreateProps.defaultValue,
      associated_content_type: customFieldCreateProps.associatedContentType,
    }
    return apiCustomFieldCreateProps
  }

  export const apiCustomFieldUpdatePropsFromModel = (
    customField: CustomFieldUpdateProps,
  ): CustomFieldUpdateProps => {
    const apiCustomFieldUpdateProps: APICustomFieldUpdateProps = {
      name: customField.name,
      description: customField.description,
      default_value: customField.defaultValue,
      associated_content_type: customField.associatedContentType,
    }
    return apiCustomFieldUpdateProps
  }

  export const customFieldValueFromAPIModel = (
    apiCustomFieldValue: APICustomFieldValue,
  ): CustomFieldValue => {
    return {
      fieldSlug: apiCustomFieldValue.field_slug,
      fieldUUID: apiCustomFieldValue.field_uuid,
      updatedAt: apiCustomFieldValue.updated_at,
      uuid: apiCustomFieldValue.uuid,
      value: apiCustomFieldValue.value,
    }
  }

  export const apiCustomFieldValueCreatePropsFromModel = (
    customFieldValueCreateProps: CustomFieldValueCreateProps,
  ): APICustomFieldValueCreateProps => {
    return {
      field_uuid: customFieldValueCreateProps.fieldUUID,
      content_type: customFieldValueCreateProps.contentType,
      object_uuid: customFieldValueCreateProps.objectUUID,
      value: customFieldValueCreateProps.value,
    }
  }
}
