const brandManifest = {
  appIcon: `${process.env.REACT_APP_BRAND_ASSET_PATH}/app-icon`,
  dashboardBanner: `${process.env.REACT_APP_BRAND_ASSET_PATH}/dashboard-banner`,
  downloadApp: `${process.env.REACT_APP_BRAND_ASSET_PATH}/download-app`,
  favicon: `${process.env.REACT_APP_BRAND_ASSET_PATH}/favicon`,
  listingAssetPlaceholder: `${process.env.REACT_APP_BRAND_ASSET_PATH}/listing-asset-placeholder`,
  listingAssetThumbnailPlaceholder: `${process.env.REACT_APP_BRAND_ASSET_PATH}/listing-asset-thumbnail-placeholder`,
  logo: `${process.env.REACT_APP_BRAND_ASSET_PATH}/logo`,
  thumbnail: `${process.env.REACT_APP_BRAND_ASSET_PATH}/thumbnail`,
  thumbailPlaceholder: `${process.env.REACT_APP_BRAND_ASSET_PATH}/thumbnail-placeholder`,
  xOriginHeader: {
    gaf: 'teams.present.gaf.com',
    ingage: 'teams.ingage.ingage.io',
  },
  appName: {
    gaf: 'GAF Present',
    ingage: 'Ingage',
  },
  dashboardBannerNav: {
    gaf: 'presentations',
    ingage: 'marketplace',
  },
  showSSOOption: {
    gaf: false,
    ingage: true,
  },
}

export type AppBrandKeys = 'gaf' | 'ingage'

export default brandManifest
