import React from 'react'
import { Outlet } from 'react-router-dom'
import FallbackSpinner from './FallbackSpinner'

const PresentLayout = (): JSX.Element => {
  return (
    <React.Suspense fallback={<FallbackSpinner />}>
      <Outlet />
    </React.Suspense>
  )
}

export default PresentLayout
