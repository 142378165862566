/**
 * Helpers for user/company roles, permissions, and labels.
 */
import { Company } from 'src/model/company/Company'
import { CompanyRole } from 'src/model/company/CompanyRole'
import { CompanyUser } from 'src/model/company/CompanyUser'
import { Team } from 'src/model/company/Team'
import { Profile } from 'src/model/user/Profile'
import { TeamRole } from 'src/model/company/TeamRole'
import { User } from 'src/model/user/User'
import { PasswordStrengthResults } from 'src/util/types'
import { FeaturesUtil } from './features/FeaturesUtil'
import { Feature } from './features/Feature'

export namespace UserUtil {
  export enum Permission {
    canCreate = 'canCreate',
    canRead = 'canRead',
    canWrite = 'canWrite',
    canDelete = 'canDelete',
    canShareInternal = 'canShareInternal',
    canSharePersonal = 'canSharePersonal',
    canDuplicate = 'canDuplicate',
    canPublicShare = 'canPublicShare',
    canViewTeamAnalytics = 'canViewTeamAnalytics',
    canLockPages = 'canLockPages',
    canImportUsers = 'canImportUsers',
    canViewMarketplace = 'canViewMarketplace',
    canInstallMarketplace = 'canInstallMarketplace',
    canRedeemMarketplace = 'canRedeemMarketplace',
    canManageThemes = 'canManageThemes',
    canManageFonts = 'canManageFonts',
  }

  export const minimumRequiredTeamPermissionToAccessSite = Permission.canCreate

  export const hasOnlyPermission = (
    role: CompanyRole,
    permission: Permission,
  ) => {
    // Move to a standard Object so we can iterate over keys and check them without running into any type errors.
    const roleObj = role as Record<string, unknown>
    for (const permissionItem in Permission) {
      // If it's the one permission we want to be true
      if (permissionItem === permission) {
        if (!roleObj[permissionItem]) {
          return false
        }
      }
      // Otherwise, we want all other permissions to be false
      else {
        if (roleObj[permissionItem]) {
          return false
        }
      }
    }
    return true
  }

  export const doesUserHavePermissionInAnyCompanyTeams = (
    company: Company,
    teams: Team[],
    permission: Permission,
    profile?: Profile,
  ) => {
    // Internal users automatically pass permission criteria
    if (profile?.isInternal) {
      return true
    }

    // Check if permission exists at the company level
    if (company.role[permission]) {
      return true
    }

    // If it's an analyst role, company-level permissions trump teams permissions, including guest admins
    if (
      permission === Permission.canViewTeamAnalytics &&
      company.role.canViewTeamAnalytics &&
      (company.role.canImportUsers || company.role.isGuest)
    ) {
      return true
    }

    // If none of the above company-level criteria pass, it's time to loop through the company's teams and see if the
    // permission exists on at lest one of the teams
    return (
      teams.findIndex(
        (team) => team.companyUUID === company.uuid && team.role?.[permission],
      ) > -1
    )
  }

  export const doesUserHavePermissionInCompanyOrTeam = (
    permission: Permission,
    company: Company,
    team?: Team,
  ) => {
    // Admins automatically pass permission criteria
    if (company.role.isAdmin) {
      return true
    }

    // check if permission exists at the company level
    if (company.role[permission]) {
      return true
    }

    // if team is given, check if permission exists at the team level
    if (team?.role?.[permission]) {
      return true
    }

    return false
  }

  export const doesUserHaveRequiredAccessLevelToAnyTeam = (teams: Team[]) => {
    return (
      teams.findIndex(
        (team) =>
          team.role?.isAdmin ||
          team.role?.canCreate ||
          team.role?.canImportUsers ||
          team.role?.canViewTeamAnalytics,
      ) > -1
    )
  }

  export const doesUserHaveRequiredAccessLevelToTeam = (team: Team) => {
    return (
      team.role?.isAdmin ||
      team.role?.canCreate ||
      team.role?.canImportUsers ||
      team.role?.canViewTeamAnalytics
    )
  }

  export const doesUserHaveRequiredAccessLevelToAnyCompany = (
    company: Company[],
  ) => {
    return (
      company.findIndex((company) =>
        doesUserHaveRequiredAccessLevelToCompany(company),
      ) > -1
    )
  }

  export const doesUserHaveDefactoAccessLevelToCompany = (company: Company) => {
    return (
      company.role.isAdmin ||
      (company.role.canRead &&
        FeaturesUtil.hasCompanyFeature(
          company,
          Feature.ALLOW_VIEW_FROM_TEAMS,
        )) ||
      (company.role.canImportUsers && company.role.isGuest) ||
      company.role.canViewTeamAnalytics
    )
  }

  export const doesUserHaveRequiredAccessLevelToCompany = (
    company: Company,
    teams?: Team[],
  ) => {
    // First check for company-level permissions (admin, guest admin, analyst)
    if (doesUserHaveDefactoAccessLevelToCompany(company)) {
      return true
    }

    // If the user is a member of teams, we first check if they are an admin or guest admin. If so, we allow access
    // before checking teams-level permissions.
    if (
      company.role.isAdmin ||
      (company.role.canImportUsers && company.role.isGuest)
    ) {
      return true
    }

    if (!teams) {
      return false
    }

    // Otherwise, we need to check for at least _one_ team with the required permissions
    return (
      teams.findIndex(
        (team) =>
          team.companyUUID === company.uuid &&
          team.role?.[minimumRequiredTeamPermissionToAccessSite],
      ) > -1
    )
  }

  export const isAdminInCompany = (company: Company) => {
    return company.role.isAdmin
  }

  export const isGuestAdminInCompany = (company: Company) => {
    return company.role.isGuest && company.role.canImportUsers
  }

  export const isCreatorInCompany = (company: Company) => {
    return company.role.canCreate
  }

  /**
   * Temporary method to determine if a user is _only_ a creator in a company (i.e. not an admin, not an analyst)
   * TODO: Remove this method once we establish Dashboards for different user roles.
   * @deprecated
   * @param company
   */
  export const isOnlyACreatorInCompany = (company: Company) => {
    return (
      isCreatorInCompany(company) &&
      !company.role.isAdmin &&
      !company.role.canImportUsers &&
      !company.role.canViewTeamAnalytics
    )
  }

  /**
   * Determines the default home route for a user based on their role.
   * TODO: Remove this method once we establish Dashboards for different user roles.
   * @deprecated
   */
  export const getHomeRouteInCompany = (company?: Company) => {
    let destination = `/company/${company?.uuid}/dashboard`
    if (!company) {
      destination = `/marketplace`
    } else if (isOnlyACreatorInCompany(company)) {
      destination = `/company/${company.uuid}/marketplace`
    }
    return destination
  }

  /**
   * Returns a user's full name, with a fallback string if the first and last name are completely blank.
   *
   * @param fallback Ideally the user's email address
   * @param firstName
   * @param lastName
   */
  export const getFullNameWithFallback = (
    firstName: string = '',
    lastName: string = '',
    fallback: string = '',
  ) => {
    const fullName = `${firstName} ${lastName}`.trim()
    return fullName.length > 0 ? fullName : fallback
  }

  /**
   * Wrapper to make calling getFullNameWithFallback() more compact by just supplying a CompanyUser or User object.
   *
   * @param user CompanyUser | User
   */
  export const getFullNameWithFallbackForUser = (
    user?: CompanyUser | User | Profile,
    defaultValue?: string,
  ) => {
    if (!user) {
      return ''
    }
    if ((user as CompanyUser).user) {
      const companyUser = user as CompanyUser
      return getFullNameWithFallback(
        companyUser.user.firstName,
        companyUser.user.lastName,
        defaultValue ?? companyUser.user.email,
      )
    } else {
      const genericUser = user as User | Profile
      return getFullNameWithFallback(
        genericUser.firstName,
        genericUser.lastName,
        defaultValue ?? 'User',
      )
    }
  }

  /**
   * Provides a list of password strength criteria and their validator functions.
   */
  export const getPasswordStrengthCriteria = () => {
    return [
      {
        criterion: 'must be between 8 and 30 characters long',
        validator: (password: string) => {
          return password.match(/(?=^.{8,30}$)/)
        },
      },
      {
        criterion: 'must have at least one uppercase letter',
        validator: (password: string) => {
          return password.match(/(?=.*[A-Z])/)
        },
      },
      {
        criterion: 'must have at least 1 lowercase letter',
        validator: (password: string) => {
          return password.match(/(?=.*[a-z])/)
        },
      },
      {
        criterion: 'must have at least one number',
        validator: (password: string) => {
          return password.match(/(?=\d)/)
        },
      },
    ]
  }

  /**
   * Checks password strength against a set of criteria.
   *
   * @param password
   */
  export const getPasswordStrengthResults = (password: string) => {
    const results: PasswordStrengthResults = {
      doesMeetCriteria: true,
      failures: [],
    }
    for (const item of getPasswordStrengthCriteria()) {
      if (!item.validator(password)) {
        results.doesMeetCriteria = false
        results.failures.push(item.criterion)
      }
    }
    return results
  }

  export const getDefaultAuthCheckTimestamp = () => {
    // 60 seconds * 60 minutes * 24 hours * 1000 milliseconds (1 day)
    // API access token lifespan is 86400 seconds
    return Date.now() + 60 * 60 * 24 * 1000
  }

  export const getDefaultAuthDestructiveActionTimestamp = () => {
    // 60 seconds * 10 minutes * 1000 milliseconds (10 minutes)
    return Date.now() + 60 * 10 * 1000
  }

  export const shouldLimitViewForGuestViewer = (
    company: Company,
    teamRole: TeamRole,
  ) => {
    if (company?.role.isGuest) {
      if (teamRole && !teamRole.canImportUsers) {
        return true
      }
    }
    return false
  }

  export const getBestDefaultRoleUUIDForAdmin = (
    userRole: CompanyRole,
    company: Company,
    roles: CompanyRole[],
  ) => {
    if (roles.length === 0) {
      console.error('Company has no roles')
      return ''
    }
    if (!userRole.isGuest) {
      return company.defaultRoleUUID || roles[0].uuid
    }
    // Find the closest thing to a Guest Viewer first
    const guestViewer = roles.find((r) => r.isGuest && r.canRead)
    if (guestViewer) {
      return guestViewer.uuid
    }
    // No Guest Viewer? Return the first Guest Role found
    const guestRole = roles.find((r) => r.isGuest)
    if (guestRole) {
      return guestRole.uuid
    }
    // No guest roles at all? Return an error and an empty string
    console.error('Company has no guest roles')
    return ''
  }
}
