import { format } from 'date-fns'
import { APIGenericUserResponse } from 'src/model/api/auth/APIGenericUserResponse'
import { APIUser } from 'src/model/api/user/APIUser'
import { APIMembership } from 'src/model/api/user/APIMembership'
import { UUID } from 'src/util/types'
import BaseAPI from './BaseAPI'
import { EnvConfig } from 'src/util/EnvConfig'
import { APILoginResponse } from 'src/model/api/auth/APILoginResponse'

/**
 * Client for user-related calls to the API.
 */
export default class UserAPI extends BaseAPI {
  /**
   * Check health of API
   */
  async getHealth() {
    return this.withoutAuth().get('/health/ok/')
  }

  /**
   * Performs a login request.
   *
   * @param email
   * @param password
   */
  async login(email: string, password: string) {
    const formData = new FormData()
    formData.append('client_id', EnvConfig.getClientID())
    formData.append('grant_type', 'password')
    formData.append('username', email.trim().toLowerCase())
    formData.append('password', password)

    return this.withoutContentHeaders()
      .withoutAuth()
      .post<APILoginResponse>('/oauth2/token/', formData)
  }

  /**
   * Performs a post login request
   *
   * @param email
   * @param accessToken
   */
  async postLogin(email: string, token: string) {
    const formData = new FormData()
    formData.append('client_id', EnvConfig.getAuth0ClientID())
    formData.append('email', email)
    formData.append('jwt', token)

    return this.withoutContentHeaders()
      .withoutAuth()
      .post<APILoginResponse>('/sso/auth0/', formData)
  }

  /**
   * Get memberships
   */
  async getMemberships() {
    return this.withAuth().getAllPages<APIMembership>('/memberships/')
  }

  /**
   * Get EULA flatpage HTML
   */
  async getEula() {
    return this.withoutAuth().get('/pages/eula_mobile/')
  }

  /**
   * Update Ingage User eula_agree response
   * We don't have an Ingage User update endpoint in Teams so this will feel like a one-off solution
   * until we really need to update the Ingage User for other reasons
   *
   * @param userUUID
   * @param eulaResponse
   */
  async updateUserEulaResponse(userUUID: UUID, eulaResponse: Date) {
    const formData = new FormData()
    formData.append(
      'eula_agreed_at',
      format(eulaResponse, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    )
    return this.withoutContentHeaders()
      .withAuth()
      .patch<APIUser>(`/users/${userUUID}/`, formData)
  }

  /**
   * Performs a logout request to the server. This is not required to log the user out from the client side (we just
   * clear out the user's token from state), but it's still good to let the server know which devices the user has
   * logged on from.
   * @param accessToken The refresh token
   */
  async logout(accessToken: string) {
    const formData = new FormData()
    formData.append('client_id', EnvConfig.getClientID())
    formData.append('token', accessToken)
    return this.withoutContentHeaders()
      .withAuth()
      .post('/oauth2/revoke_token/', formData)
  }

  /**
   * Searches a user from a company (if any found).
   *
   * @param searchStr
   * @param companyID
   */
  async searchForUserInCompany(searchStr: string, companyID: UUID) {
    return this.withAuth().get(
      `/team/api/company/${companyID}/users/?search=${searchStr}`,
    )
  }

  /**
   * Retrieves a user and their data by their UUID.
   *
   * @param userID
   */
  async getUserByUUID(userID: UUID) {
    return this.withAuth().get(`/users/${userID}/?content=false`)
  }

  /**
   * Requests the API to send an email containing a temporary passkey to the specified address. The passkey is used for
   * password reset requests.
   *
   * @param email
   */
  async emailTemporaryPasskey(email: string) {
    const postData = {
      email: email,
      client_id: EnvConfig.getClientID(),
    }

    const queryParams = {
      app: 'teams',
    }
    const url = `/password/forgot/`
    return this.withoutAuth().post<APIGenericUserResponse>(url, postData, {
      params: queryParams,
    })
  }

  /**
   * Changes a user's password to a new password.
   *
   * @param email
   * @param passkey
   * @param newPassword
   */
  async resetPassword(email: string, passkey: string, newPassword: string) {
    const postData = {
      email,
      passkey,
      password: newPassword,
    }
    const queryParams = {
      app: 'teams',
    }
    const url = '/password/reset/'
    return this.withoutAuth().post<APIGenericUserResponse>(url, postData, {
      params: queryParams,
    })
  }

  /**
   * Activates a new user.
   *
   * @param userUUID
   * @param password
   * @param confirmPassword
   */
  async activateUser(
    userUUID: UUID,
    password: string,
    firstName?: string,
    lastName?: string,
  ) {
    const postData: Record<string, string> = {
      uid: userUUID,
      password,
    }

    if (firstName) {
      postData['first_name'] = firstName
    }

    if (lastName) {
      postData['last_name'] = lastName
    }

    const url = '/team/api/activate_user/'
    return this.withoutAuth().post(url, postData)
  }
}
