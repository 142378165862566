/**
 * Provides methods to ingest client-side data models from API data for Companies and Teams.
 */
import { APICompanyInternal } from 'src/model/api/company/APICompanyInternal'
import { APITeamRole } from 'src/model/api/company/APITeamRole'
import { CompanyInternal } from 'src/model/company/CompanyInternal'
import { TeamRole } from 'src/model/company/TeamRole'
import { APIMessage } from '../api/company/APIMessage'
import { Message } from './Message'
import { APICompany } from '../api/company/APICompany'
import { Company } from './Company'
import { APIActivityLogItem } from '../api/company/APIActivityLogItem'
import { ActivityLogItem } from './ActivityLogItem'
import { APICompanyUser } from '../api/company/APICompanyUser'
import { CompanyUser } from './CompanyUser'
import { APICompanyRole } from '../api/company/APICompanyRole'
import { CompanyRole } from './CompanyRole'
import { APINewUserResponse } from '../api/company/APINewUserResponse'
import { NewUserResponse } from './NewUserResponse'
import { APIUserDetailAnalyticsEvent } from '../api/company/APIUserDetailAnalyticsEvent'
import { UserDetailAnalyticsEvent } from './UserDetailAnalyticsEvent'
import { APITeam } from 'src/model/api/company/APITeam'
import { APITeamMember } from 'src/model/api/company/APITeamMember'
import { Team } from 'src/model/company/Team'
import { TeamMember } from 'src/model/company/TeamMember'
import { CustomFieldsModel } from 'src/model/customfields'
import { DynamicKeysModel } from 'src/model/dynamickeys'

export namespace CompanyModel {
  export const messageFromAPIMessage = (apiMessage: APIMessage) => {
    const message: Message = {
      id: apiMessage.id,
      title: apiMessage.title,
      details: apiMessage.details,
      affectedVersions: apiMessage.affected_versions,
      severity: apiMessage.severity,
      expiresAt: apiMessage.expires_at,
      createdAt: apiMessage.created_at,
      updatedAt: apiMessage.updated_at,
      createdBy: apiMessage.created_by,
      updatedBy: apiMessage.updated_by,
    }
    return message
  }

  export const companyFromAPIModel = (apiCompany: APICompany) => {
    const company: Company = {
      id: apiCompany.id,
      uuid: apiCompany.uuid,
      name: apiCompany.name,
      phone: apiCompany.phone,
      url: apiCompany.url,
      thumbnail: apiCompany.thumbnail,
      role: {
        canCreate: apiCompany.role.can_create,
        canDelete: apiCompany.role.can_delete,
        canDuplicate: apiCompany.role.can_duplicate,
        canImportUsers: apiCompany.role.can_import_users,
        canLockPages: apiCompany.role.can_lock_pages,
        canPublicShare: apiCompany.role.can_public_share,
        canRead: apiCompany.role.can_read,
        canShareInternal: apiCompany.role.can_share_internal,
        canSharePersonal: apiCompany.role.can_share_personal,
        canViewTeamAnalytics: apiCompany.role.can_view_team_analytics,
        canWrite: apiCompany.role.can_write,
        company: apiCompany.role.company,
        companyUUID: apiCompany.role.company_uuid,
        id: apiCompany.role.id,
        isAdmin: apiCompany.role.is_admin,
        isDefault: apiCompany.role.is_default,
        isGuest: apiCompany.role.is_guest,
        isGuestDefault: apiCompany.role.is_guest_default,
        name: apiCompany.role.name,
        uuid: apiCompany.role.uuid,
        canViewMarketplace: apiCompany.role.can_view_marketplace,
        canInstallMarketplace: apiCompany.role.can_install_marketplace,
        canRedeemMarketplace: apiCompany.role.can_redeem_marketplace,
        canManageThemes: apiCompany.role.can_manage_themes,
        canManageFonts: apiCompany.role.can_manage_fonts,
      },
      allowGuestTeams: apiCompany.allow_guest_teams,
      canViewGuestInfo: apiCompany.can_view_guest_info,
      allowCustomFonts: apiCompany.allow_custom_fonts,
      defaultRoleUUID: apiCompany.default_role_uuid,
      companyUserUUID: apiCompany.company_user_uuid,
      isProtected: apiCompany.is_protected,
      isSSO: apiCompany.is_sso,
      companyType: apiCompany.company_type,
      canPresentFromLibrary: apiCompany.can_present_from_library,
      launchControlAlertMessage: apiCompany.launch_control_alert_message,
      allowIntegrations: apiCompany.allow_integrations,
      features: apiCompany.features,
      hubspotID: apiCompany.hubspot_id,
      hubspot_info: apiCompany.hubspot_info,
      num_paid_users: apiCompany.num_paid_users,
      organization_uuids: apiCompany.organization_uuids,
      customFields: apiCompany.customfields.map((apiCustomField) =>
        CustomFieldsModel.customFieldFromAPIModel(apiCustomField),
      ),
      customFieldValues: apiCompany.customfieldvalues.map(
        (apiCustomFieldValue) =>
          CustomFieldsModel.customFieldValueFromAPIModel(apiCustomFieldValue),
      ),
      dynamicKeys: apiCompany.dynamickeys.map((apiDynamicKey) =>
        DynamicKeysModel.dynamicKeyFromAPIModel(apiDynamicKey),
      ),
    }
    return company
  }

  export const companyInternalFromAPIModel = (
    apiCompanyInternal: APICompanyInternal,
  ) => {
    const companyInternal: CompanyInternal = {
      allowGuestTeams: apiCompanyInternal.allow_guest_teams,
      canViewGuestInfo: apiCompanyInternal.can_view_guest_info,
      id: apiCompanyInternal.id,
      isProtected: apiCompanyInternal.is_protected,
      name: apiCompanyInternal.name,
      phone: apiCompanyInternal.phone,
      roles: apiCompanyInternal.roles.map((role) =>
        companyRoleFromAPIModel(role),
      ),
      url: apiCompanyInternal.url,
      uuid: apiCompanyInternal.uuid,
      hubspotID: apiCompanyInternal.hubspot_id,
    }
    return companyInternal
  }

  export const activityLogItemFromAPIModel = (
    apiActivityLogItem: APIActivityLogItem,
  ) => {
    const activityLogItem: ActivityLogItem = {
      eventType: apiActivityLogItem.event_type,
      id: apiActivityLogItem.uuid,
      message: apiActivityLogItem.message,
      multiEventId: apiActivityLogItem.multi_event_id,
      objectRepr: apiActivityLogItem.object_repr,
      timestamp: apiActivityLogItem.event_dt,
      user: apiActivityLogItem.user,
    }
    return activityLogItem
  }

  export const companyUserFromAPIModel = (apiCompanyUser: APICompanyUser) => {
    const companyUser: CompanyUser = {
      company: apiCompanyUser.company,
      companyUUID: apiCompanyUser.company_uuid,
      createdAt: apiCompanyUser.created_at,
      customFieldValues: apiCompanyUser.customfieldvalues.map(
        (apiCustomFieldValue) =>
          CustomFieldsModel.customFieldValueFromAPIModel(apiCustomFieldValue),
      ),
      features: apiCompanyUser.features,
      id: apiCompanyUser.id,
      isCompanyAdmin: apiCompanyUser.is_company_admin,
      isGuestUser: apiCompanyUser.is_guest_user,
      isPaid: apiCompanyUser.is_paid,
      phone: apiCompanyUser.phone,
      role: {
        canCreate: apiCompanyUser.role.can_create,
        canDelete: apiCompanyUser.role.can_delete,
        canDuplicate: apiCompanyUser.role.can_duplicate,
        canImportUsers: apiCompanyUser.role.can_import_users,
        canLockPages: apiCompanyUser.role.can_lock_pages,
        canPublicShare: apiCompanyUser.role.can_public_share,
        canRead: apiCompanyUser.role.can_read,
        canShareInternal: apiCompanyUser.role.can_share_internal,
        canSharePersonal: apiCompanyUser.role.can_share_personal,
        canViewTeamAnalytics: apiCompanyUser.role.can_view_team_analytics,
        canViewMarketplace: apiCompanyUser.role.can_view_marketplace,
        canInstallMarketplace: apiCompanyUser.role.can_install_marketplace,
        canRedeemMarketplace: apiCompanyUser.role.can_redeem_marketplace,
        canManageThemes: apiCompanyUser.role.can_manage_themes,
        canManageFonts: apiCompanyUser.role.can_manage_fonts,
        canWrite: apiCompanyUser.role.can_write,
        company: apiCompanyUser.role.company,
        companyUUID: apiCompanyUser.role.company_uuid,
        id: apiCompanyUser.role.id,
        isAdmin: apiCompanyUser.role.is_admin,
        isDefault: apiCompanyUser.role.is_default,
        isGuest: apiCompanyUser.role.is_guest,
        isGuestDefault: apiCompanyUser.role.is_guest_default,
        name: apiCompanyUser.role.name,
        uuid: apiCompanyUser.role.uuid,
      },
      status: apiCompanyUser.status,
      teamCount: apiCompanyUser.team_count,
      teams: apiCompanyUser.teams ?? [],
      user: {
        dateJoined: apiCompanyUser.user.date_joined,
        email: apiCompanyUser.user.email,
        emailStatus: apiCompanyUser.user.email_status,
        firstName: apiCompanyUser.user.first_name,
        id: apiCompanyUser.user.id,
        isInternal: apiCompanyUser.user.internal,
        isActive: apiCompanyUser.user.is_active,
        lastName: apiCompanyUser.user.last_name,
        photo: apiCompanyUser.user.photo,
        thumbnail: apiCompanyUser.user.photo,
        username: apiCompanyUser.user.username,
      },
      uuid: apiCompanyUser.uuid,
    }
    return companyUser
  }

  export const companyRoleFromAPIModel = (apiCompanyRole: APICompanyRole) => {
    const companyRole: CompanyRole = {
      canCreate: apiCompanyRole.can_create,
      canDelete: apiCompanyRole.can_delete,
      canDuplicate: apiCompanyRole.can_duplicate,
      canImportUsers: apiCompanyRole.can_import_users,
      canLockPages: apiCompanyRole.can_lock_pages,
      canPublicShare: apiCompanyRole.can_public_share,
      canRead: apiCompanyRole.can_read,
      canShareInternal: apiCompanyRole.can_share_internal,
      canSharePersonal: apiCompanyRole.can_share_personal,
      canViewTeamAnalytics: apiCompanyRole.can_view_team_analytics,
      canWrite: apiCompanyRole.can_write,
      company: apiCompanyRole.company,
      companyUUID: apiCompanyRole.company_uuid,
      id: apiCompanyRole.id,
      isAdmin: apiCompanyRole.is_admin,
      isDefault: apiCompanyRole.is_default,
      isGuest: apiCompanyRole.is_guest,
      isGuestDefault: apiCompanyRole.is_guest_default,
      name: apiCompanyRole.name,
      uuid: apiCompanyRole.uuid,
      canInstallMarketplace: apiCompanyRole.can_install_marketplace,
      canRedeemMarketplace: apiCompanyRole.can_redeem_marketplace,
      canManageFonts: apiCompanyRole.can_manage_fonts,
      canManageThemes: apiCompanyRole.can_manage_themes,
      canViewMarketplace: apiCompanyRole.can_view_marketplace,
    }
    return companyRole
  }

  export const newUserResponseFromAPIModel = (
    apiNewUserResponse: APINewUserResponse,
  ) => {
    const newUserResponse: NewUserResponse = {
      company: apiNewUserResponse.company,
      companyUUID: apiNewUserResponse.company_uuid,
      id: apiNewUserResponse.id,
      isGuestUser: apiNewUserResponse.is_guest_user,
      phone: apiNewUserResponse.phone,
      role: {
        canCreate: apiNewUserResponse.role.can_create,
        canDelete: apiNewUserResponse.role.can_delete,
        canDuplicate: apiNewUserResponse.role.can_duplicate,
        canImportUsers: apiNewUserResponse.role.can_import_users,
        canLockPages: apiNewUserResponse.role.can_lock_pages,
        canPublicShare: apiNewUserResponse.role.can_public_share,
        canRead: apiNewUserResponse.role.can_read,
        canShareInternal: apiNewUserResponse.role.can_share_internal,
        canSharePersonal: apiNewUserResponse.role.can_share_personal,
        canViewTeamAnalytics: apiNewUserResponse.role.can_view_team_analytics,
        canWrite: apiNewUserResponse.role.can_write,
        company: apiNewUserResponse.role.company,
        companyUUID: apiNewUserResponse.role.company_uuid,
        id: apiNewUserResponse.role.id,
        isAdmin: apiNewUserResponse.role.is_admin,
        isDefault: apiNewUserResponse.role.is_default,
        isGuest: apiNewUserResponse.role.is_guest,
        isGuestDefault: apiNewUserResponse.role.is_guest_default,
        name: apiNewUserResponse.role.name,
        uuid: apiNewUserResponse.role.uuid,
        canInstallMarketplace: apiNewUserResponse.role.can_install_marketplace,
        canRedeemMarketplace: apiNewUserResponse.role.can_redeem_marketplace,
        canManageFonts: apiNewUserResponse.role.can_manage_fonts,
        canManageThemes: apiNewUserResponse.role.can_manage_themes,
        canViewMarketplace: apiNewUserResponse.role.can_view_marketplace,
      },
      status: apiNewUserResponse.status,
      teams: apiNewUserResponse.teams,
      user: {
        dateJoined: apiNewUserResponse.user.date_joined,
        email: apiNewUserResponse.user.email,
        emailStatus: apiNewUserResponse.user.email_status,
        firstName: apiNewUserResponse.user.first_name,
        id: apiNewUserResponse.user.id,
        internal: apiNewUserResponse.user.internal,
        isActive: apiNewUserResponse.user.is_active,
        lastName: apiNewUserResponse.user.last_name,
        photo: apiNewUserResponse.user.photo,
        thumbnail: apiNewUserResponse.user.thumbnail,
        username: apiNewUserResponse.user.username,
      },
      uuid: apiNewUserResponse.uuid,
    }
    return newUserResponse
  }

  export const userDetailAnalyticsEventFromAPIModel = (
    apiUserDetailAnalyticsEvent: APIUserDetailAnalyticsEvent,
  ) => {
    const userDetailAnalyticsEvent: UserDetailAnalyticsEvent = {
      date: apiUserDetailAnalyticsEvent.date,
      desktopVersion: apiUserDetailAnalyticsEvent.desktop_version,
      duration: apiUserDetailAnalyticsEvent.duration,
      email: apiUserDetailAnalyticsEvent.email,
      iosVersion: apiUserDetailAnalyticsEvent.ios_version,
      presentation: apiUserDetailAnalyticsEvent.presentation,
      userUUID: apiUserDetailAnalyticsEvent.user_id,
    }
    return userDetailAnalyticsEvent
  }

  export const teamFromAPIModel = (apiTeam: APITeam) => {
    const team: Team = {
      company: apiTeam.company,
      companyUUID: apiTeam.company_uuid,
      contentsURL: apiTeam.contents_url,
      customFieldValues: apiTeam.customfieldvalues.map((apiCustomFieldValue) =>
        CustomFieldsModel.customFieldValueFromAPIModel(apiCustomFieldValue),
      ),
      features: apiTeam.features,
      id: apiTeam.id,
      membersURL: apiTeam.members_url,
      userCount: apiTeam.user_count,
      name: apiTeam.name,
      role: teamRoleFromAPIModel(apiTeam.role),
      thumbnail: apiTeam.thumbnail,
      uuid: apiTeam.uuid,
    }
    return team
  }

  export const teamRoleFromAPIModel = (apiTeamRole?: APITeamRole) => {
    if (!apiTeamRole) {
      return undefined
    }
    const teamRole: TeamRole = {
      canCreate: apiTeamRole?.can_create,
      canDelete: apiTeamRole.can_delete,
      canDuplicate: apiTeamRole.can_duplicate,
      canImportUsers: apiTeamRole.can_import_users,
      canLockPages: apiTeamRole.can_lock_pages,
      canPublicShare: apiTeamRole.can_public_share,
      canRead: apiTeamRole.can_read,
      canShareInternal: apiTeamRole.can_share_internal,
      canSharePersonal: apiTeamRole.can_share_personal,
      canViewTeamAnalytics: apiTeamRole.can_view_team_analytics,
      canWrite: apiTeamRole.can_write,
      company: apiTeamRole.company,
      companyUUID: apiTeamRole.company_uuid,
      id: apiTeamRole.id,
      isAdmin: apiTeamRole.is_admin,
      isDefault: apiTeamRole.is_default,
      isGuest: apiTeamRole.is_guest,
      isGuestDefault: apiTeamRole.is_guest_default,
      name: apiTeamRole.name,
      uuid: apiTeamRole.uuid,
      canInstallMarketplace: apiTeamRole.can_install_marketplace,
      canRedeemMarketplace: apiTeamRole.can_redeem_marketplace,
      canManageFonts: apiTeamRole.can_manage_fonts,
      canManageThemes: apiTeamRole.can_manage_themes,
      canViewMarketplace: apiTeamRole.can_view_marketplace,
    }
    return teamRole
  }

  export const teamMemberFromAPIModel = (apiTeamMember: APITeamMember) => {
    const teamMember: TeamMember = {
      id: apiTeamMember.id,
      role: apiTeamMember.role,
      roleUUID: apiTeamMember.role_uuid,
      team: apiTeamMember.team,
      teamUUID: apiTeamMember.team_uuid,
      user: companyUserFromAPIModel(apiTeamMember.user),
      uuid: apiTeamMember.uuid,
    }
    return teamMember
  }
}
